import { useEffect } from "react";
import useQuery from "../../../hooks/useQuery";
import { auth, functions } from "../../../firebase/firebase";
import { useFetchUser } from "../../../hooks/useFetchUser";
import { httpsCallable } from "firebase/functions";
import { useLocation } from "react-router-dom";

const SubDomainRedirection = () => {
    const query = useQuery();
    const { currentParticipant } = useFetchUser();
    const { pathname } = useLocation();

    // const getCookie = (name: string): string | undefined => {
    //     const value = `; ${document.cookie}`;
    //     const parts = value.split(`; ${name}=`);
    //     if (parts.length === 2) return parts.pop()?.split(';').shift();
    // };

    // redirect to syn2 if pscid includes syn2
    useEffect(() => {
        const syn2Redirection = async () => {

            // const isLoggedOut = getCookie('isLoggedOut');
            // console.log("isLoggedOut cookie value:", isLoggedOut);

            // if (isLoggedOut === 'true') {
            //     console.log("User is logged out, no redirection");
            //     return;
            // }

            const referrer = document.referrer;
            const isFromSubdomain = referrer.includes("syn2.brainhealthpro.ca") || referrer.includes("sleep-library.brainhealthpro.ca");
            const isCompletedUrl = window.location.href.includes("completed");

            if (isFromSubdomain && isCompletedUrl) {
                console.log("User came from subdomain and completed a chapter, redirecting back to subdomain");

                if (referrer.includes("syn2.brainhealthpro.ca")) {
                    window.location.href = `https://syn2.brainhealthpro.ca`;
                } else if (referrer.includes("sleep-library.brainhealthpro.ca")) {
                    window.location.href = `https://sleep-library.brainhealthpro.ca`;
                }
                return;
            }

            if (currentParticipant?.id && currentParticipant.study && !window.location.href.includes("completed/")) {
                const tokenQuery = query.get("token");
                const createCustomToken = httpsCallable(functions, "createCustomToken");
                const tokenPayload = await createCustomToken({ uid: currentParticipant.id });

                const authToken = tokenPayload.data as string;
                localStorage.setItem('authToken', authToken);

                if (!window.location.href.includes("http://localhost:8283")) {
                    if (!tokenQuery) {
                        if (currentParticipant.study === "synergic2") {
                            await auth.signOut();
                            window.location.href = `https://syn2.brainhealthpro.ca/?token=${tokenPayload.data}`;
                        }
                        if (currentParticipant.study === "sleep") {
                            await auth.signOut();
                            window.location.href = `https://sleep-library.brainhealthpro.ca/?token=${tokenPayload.data}`;
                        }
                    }
                }
            }
        };
        syn2Redirection();
    }, [currentParticipant, pathname]);

    return <></>;
};

export default SubDomainRedirection;
