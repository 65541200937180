import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";

// Interfaces
import { AdminUser } from "../interfaces/AdminUser";
import { Participant } from "../interfaces/Participant";

// Firebase
import { auth } from "../firebase/firebase";

// Hooks
import useDb from "../hooks/useDb";

// Utils
import { weeksBetween } from "../utils/date";

const initState = {
    currentAdmin: null,
    currentParticipant: null,
    currentWeek: 0,
    fetchingUser: true,
    loading: false,
    // eslint-disable-next-line
    setLoading: (value: any) => {
        //
    },
    resetStates: () => {
        //
    },
    deletedUser: false,
    // eslint-disable-next-line
    setDeletedUser: (value: any) => {
        //
    },
};

const useFetchUserImpl = () => {
    // States
    const [loading, setLoading] = useState(false);
    const [currentAdmin, setCurrentAdmin] = useState<AdminUser | null>(null);
    const [currentParticipant, setCurrentParticipant] = useState<Participant | null>(null);
    const [currentWeek, setCurrentWeek] = useState(0);
    const [fetchingUser, setFetchingUser] = useState<boolean>(true);
    const [deletedUser, setDeletedUser] = useState(false);

    const adminUserRequests = useDb<AdminUser>("Users", currentAdmin);
    const participantRequests = useDb<Participant>("Participants", currentAdmin);

    const resetStates = () => {
        setCurrentParticipant(null);
        setCurrentAdmin(null);
        setCurrentWeek(0);
    };

    useEffect(() => {
        let unsubParticipant: any = null;
        let unsubAdmin: any = null;

        auth.onAuthStateChanged(async res => {
            if (res) {
                setFetchingUser(true);
                if (res.displayName === "Participants") {
                    unsubParticipant = participantRequests.onSnapshot({
                        callback: (participant: Participant) => {
                            if (participant) {
                                if (!participant.isDeleted) {
                                    setCurrentParticipant(participant);
                                    if (participant && participant.cycleStart) {
                                        const today = new Date();
                                        if (today >= participant.cycleStart) setCurrentWeek(weeksBetween(today, participant.cycleStart));
                                        else setCurrentWeek(1);
                                    }
                                }
                            } else setDeletedUser(true);

                            setCurrentAdmin(null);
                            setFetchingUser(false);
                        },
                        id: res.uid,
                    });
                } else {
                    unsubAdmin = adminUserRequests.onSnapshot({
                        callback: (adminUser: AdminUser) => {
                            setCurrentAdmin(adminUser);
                            setCurrentParticipant(null);
                            setFetchingUser(false);
                        },
                        id: res.uid,
                    });
                }
            } else {
                setCurrentAdmin(null);
                setCurrentParticipant(null);
                resetStates();
                setFetchingUser(false);
            }
        });
        return () => {
            if (typeof unsubParticipant === "function") unsubParticipant();
            if (typeof unsubAdmin === "function") unsubAdmin();
        };
    }, []);

    return { currentAdmin, currentParticipant, currentWeek, fetchingUser, loading, setLoading, resetStates, deletedUser, setDeletedUser };
};

export const useFetchUser = singletonHook(initState, useFetchUserImpl);
